/*

*/


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_TICKERMODELS} from './config'
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';
import Select from 'react-select';
import { modelOptions } from './cryptoList';

import {columns} from './tickerModelsTableColumns';
import {lstmcolumns} from './tickerModelsTableLSTMColumns';
import {columnsMth} from './tickerModelsTableColumnsMth';
import {lstmcolumnsMth} from './tickerModelsTableLSTMColumnsMth';
import {columns2} from './tickerModelsTableColumns2';

// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
// import Chart_Scatter from './Chart_Scatter';
// import Chart_StockLine from './Chart_StockLine';
import Chart_StockLine from './Chart_StockLine_intraday';

import BootstrapTable from 'react-bootstrap-table-next';

import Footer from './Footer';

const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

class FoundationTS extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // },

        this.state = {
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            data: null,
            valid_ticker: true,
            selectedOption: null,
            predData: [
                {'ticker': "BTC", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
                {'ticker': "ETH", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
            ],
            ticker_names: null,
            period: 'H',
            num_of_period: 1,
            prev_date : null,
            pred_date : null,
            is_505_error: false

        };

        console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputStartDate = this.handleInputStartDate.bind(this);
        this.handleInputEndDate = this.handleInputEndDate.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);

    }
    

    handleClick(e) {
        e.preventDefault(); 
        // if (!this.state.valid_ticker || !this.state.valid_enddate || !this.state.valid_startdate) {
        //     return;
        // }
        console.log(this.state.strat_ar);
        this.setState( 
            prevState => ( {
                loading: 1,
            })
        );
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        //axios.post("http://100.1.58.77:5000/api/strategy1", {
        axios.post(`${API_URL_TICKERMODELS}`, {
                tickers: this.state.ticker_r
                // period: this.state.period,
                // num_of_period: this.state.num_of_period
            })
            //.then(function(response) {
            .then( (response) => {
                console.log(response);
                this.setState (
                    prevState => ( {
                        loading: 2, // done. received response
                        //groupname: response.data[0]['name'],
                        //data: response.data
                        data: response.data['resdata'],
                        ticker_names : response.data['ticker_names'],
                        predData : response.data['predData'],
                        prev_price_date: response.data['prev_price_date'],
                        predData160mth : response.data['predData160mth'],
                        prev_price_date_160mth: response.data['prev_price_date_160mth'],
                        predData190mth : response.data['predData190mth'],
                        prev_price_date_190mth: response.data['prev_price_date_190mth'],
                        predData190wk : response.data['predData190wk'],
                        prev_price_date_190wk: response.data['prev_price_date_190wk'],
                        is_505_error: false
                        // predData2 : response.data['predData2'],
                        // prev_price_date2: response.data['prev_price_date2'],
                    }),
                    () => console.log(this.state)
                );
                console.log("inside call back");
            })
            // .catch(function (error) {
            .catch(err => {
                // console.log(error);
                console.log("EEEEEEEERRRRRRORRR");
                this.setState (
                    prevState => ( {
                        loading: 2,
                        is_505_error: true
                    }),
                    () => console.log(this.state.is_505_error)
                );
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;
        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 0
            }),
            () => console.log(this.state.ticker_r)
        );

        //e.preventDefault();
    }

    handleInputStartDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                start_date: value,
                valid_startdate: (Date.parse(value) >= Date.parse("2010-01-01")) && (Date.parse(value) <= Date.parse("2021-2-2") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }

    handleInputEndDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                end_date: value,
                valid_enddate: ( Date.parse(value) > Date.parse(this.state.start_date)) && ( Date.parse(value) <= Date.parse("2021-01-29") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.end_date)
        );
    }

    handleStrat = selectedOption =>{
        this.setState(
            {selectedOption: selectedOption},
            () => console.log('Options selected:', this.state.selectedOption)

        );
    };

    render() {
        const is_505_error =this.state.is_505_error;
        console.log("is_505_error:", is_505_error);

        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display
            if (is_505_error){

                return (
                        <div id="pf1"><h1>Prediction</h1>

                        {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}
                        <div class="alert alert-primary" role="alert">
                        <h5>Overview</h5>
                            <p>Based on the prediction models implemented in the platform with various paremeters, the past performances (Weekly/Monthly) of different timeframe are collected with respect to:</p>
                            <ul>
                                <li>Hit ratio</li>
                                <li>MSE (to be added)</li>
                            </ul>
                        <h5>Universe</h5>
                            <p>2500+ stocks in U.S. Market </p>
                        <h5>Models</h5>
                            <ul>
                                <li>ARIMA</li>
                                    <ul>parameteres
                                        <li>p :AR order 0, 1, 2</li>
                                        <li>d :Integrated 1</li>
                                        <li>q :MA order 0, 1, 2</li>
                                        <li>lookback: 20, 60, 120</li>
                                    </ul>
                                <li>LSTM</li>
                                    <ul>parameteres
                                        <li>p : lag (determined by partial autocorrelation function for each stock) </li>
                                        <li>lookback: all avaiable data</li>
                                    </ul>
                            </ul>

                            {/* <p>From 2021-1-22</p> */}
                        </div>
                        <div className="form1_input">
                            <form onSubmit={this.handleClick}>
                                <ChInput  
                                    inputType={"text"}
                                    title={"Ticker"}
                                    name={"ticker_name"}
                                    value = {this.state.ticker_r}
                                    placeholder="Enter ticker" 
                                    handleChange={this.handleInput}
                                    //handlechange={this.handleInput}
                                />{" "}

                                <ChButton
                                    action={this.handleClick}
                                    type={"btn-outline-primary"}
                                    title={"Run"}
                                    style={buttonStyle}
                                />{" "}
                                {/* <div class="alert alert-primary" role="alert">
                                {this.state.msg}
                                </div>
                                {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                                {"The optimization is not feasible!"}
                                                                </div> 
                                } */}
                            </form>
                        </div>
                        
                        <div>
                            {/* <iframe src="error500.html" frameborder="0" scrolling="no" onload="resizeIframe(this)" ></iframe> */}
                            <iframe src="error500.html" frameborder="0" scrolling="no" height="600" width="800" ></iframe>

                        </div>
                        <Footer />
                        {/* <footer class="mastfoot mt-auto">
                                <div class="inner">
                                    <p>© 2023 Copyright Chris IJ Hwang</p>
                                </div>
                                <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                                </p>
                        </footer> */}

                        </div>
                );

            } // 505 error if end
            else { // 505 error else


                return ( 
                    <div id="pf1"><h1>Prediction</h1>

                        {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}
                        <div class="alert alert-primary" role="alert">
                        <h5>Overview</h5>
                            <p>Based on the prediction models implemented in the platform with various paremeters, the past performances (Weekly/Monthly) of different timeframe are collected with respect to:</p>
                            <ul>
                                <li>Hit ratio</li>
                                <li>MSE (to be added)</li>
                            </ul>
                        <h5>Universe</h5>
                            <p>2500+ stocks in U.S. Market </p>
                        <h5>Models</h5>
                            <ul>
                                <li>ARIMA</li>
                                    <ul>parameteres
                                        <li>p :AR order 0, 1, 2</li>
                                        <li>d :Integrated 1</li>
                                        <li>q :MA order 0, 1, 2</li>
                                        <li>lookback: 20, 60, 120</li>
                                    </ul>
                                <li>LSTM</li>
                                    <ul>parameteres
                                        <li>p : lag (determined by partial autocorrelation function for each stock) </li>
                                        <li>lookback: all avaiable data</li>
                                    </ul>
                            </ul>

                            {/* <p>From 2021-1-22</p> */}
                        </div>
                        <div className="form1_input">
                            <form onSubmit={this.handleClick}>
                                <ChInput  
                                    inputType={"text"}
                                    title={"Ticker"}
                                    name={"ticker_name"}
                                    value = {this.state.ticker_r}
                                    placeholder="Enter ticker" 
                                    handleChange={this.handleInput}
                                    //handlechange={this.handleInput}
                                />{" "}

                                <ChButton
                                    action={this.handleClick}
                                    type={"btn-outline-primary"}
                                    title={"Run"}
                                    style={buttonStyle}
                                />{" "}
                                {/* <div class="alert alert-primary" role="alert">
                                {this.state.msg}
                                </div>
                                {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                                {"The optimization is not feasible!"}
                                                                </div> 
                                } */}
                            </form>


                            <h3>ARIMA models</h3>

                            <h4>Weekly Return Prediction at : {this.state.prev_price_date}</h4>
                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.predData } 
                                columns={ columns } 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            />

                            {/* <h4>Monthly Return Prediction at : {this.state.prev_price_date_160mth}</h4>
                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.predData160mth } 
                                columns={ columnsMth } 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            /> */}
                            <h3>LSTM models</h3>
                            <h4>Weekly Return Prediction at : {this.state.prev_price_date_190wk}</h4>
                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.predData190wk } 
                                columns={ columns} 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            />
                            {/* <h4>Monthly Return Prediction at : {this.state.prev_price_date_190mth}</h4>
                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.predData190mth } 
                                columns={ columnsMth } 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            /> */}
                            {/* <h3>ML models - XGBoost</h3>
                            <h4>Prediction at : {this.state.prev_price_date2}</h4>
                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.predData2 } 
                                columns={ columns2 } 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            /> */}
                        </div>
                        
                        <div>
                            {/* {this.state.data.map((dd, index) =>
                                
                                //<Chart_StockLine key={index} title_text={"ticker : " + index} 
                                // <Chart_StockLine key={index} title_text={"ticker : " + this.state.ticker_r} 
                                <Chart_StockLine key={index} title_text={" " + this.state.ticker_names[index]} 
                                data = {dd}/>,
                                // <Chart_StockLine  title_text={"title_text"} 
                                // data = {dd[0]}/>
                                )
                                
                            } */}

                            {/* <Chart_StockLine  title_text={this.state.groupname} 
                                    data = {this.state.data}/>  */}
                        </div>
                        <Footer />
                        {/* <footer class="mastfoot mt-auto">
                        <div class="inner">
                                    <p>© 2023 Copyright Chris IJ Hwang</p>
                                </div>
                                <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                                </p>
                            </footer> */}
                        
                    </div>
                );
            } // 505 error else

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Prediction</h1>
                    {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}

                    <div class="alert alert-secondary" role="alert">
                    <h5>Overview</h5>
                            <p>Based on the prediction models implemented in the platform with various paremeters, the past performances (Weekly/Monthly) of different timeframe are collected with respect to:</p>
                            <ul>
                                <li>Hit ratio</li>
                                <li>MSE (to be added)</li>
                            </ul>
                        <h5>Universe</h5>
                            <p>500+ stocks in U.S. Market </p>
                        <h5>Models</h5>
                            <ul>
                                <li>ARIMA</li>
                                    <ul>parameteres
                                        <li>p :AR order 0, 1, 2</li>
                                        <li>d :Integrated 1</li>
                                        <li>q :MA order 0, 1, 2</li>
                                        <li>lookback: 20, 60, 120</li>
                                    </ul>
                                <li>LSTM</li>
                                    <ul>parameteres
                                        <li>p : lag (determined by partial autocorrelation function for each stock) </li>
                                        <li>lookback: all avaiable data</li>
                                    </ul>
                            </ul>
                        {/* <p>From 2021-1-22</p> */}
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>

                            <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                                //handlechange={this.handleInput}
                            />{" "}

                            {/* <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Start Predict"}
                                style={buttonStyle}
                            />{" "} */}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        
                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                    <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                        </footer> */}
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Foundation Model Time Series</h1>
                    {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}

                    <div class="alert alert-primary" role="alert">
                    <h3>This page is currently available on a non-SSL site. Please click <a href="http://cedge.us:8060" target="_blank" >here</a> if you wish to visit the non-SSL site.
                    </h3>
                        <h5>Lag-Llama Model</h5>

                       
                        {/* <p>From 2021-1-22</p> */}
                    </div>

                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                    </footer> */}
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default FoundationTS