import React, { Component } from "react";
import {
    Route,
    NavLink,
    HashRouter,
    Link

} from "react-router-dom";
import Home from "./Home";
import PortFolioForm1 from "./PortfolioForm1";
import HCAForm1 from "./HCAForm1";
import REA from "./REA";
import Dashboard from "./Dashboard";
import Strategy1 from "./Strategy1";
import Strategy2 from "./Strategy2";
import Crypto1 from "./Crypto1";
import Crypto2 from "./Crypto2";
import UsingReactPlotly from "./UsingReactPlotly";
import UsingPlotly from "./UsingPlotly";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import {AuthProvider} from './context/AuthContext';

class Main extends Component {
    render() {
        return (
            <AuthProvider>
                <Navbar />
            </AuthProvider>
            // <HashRouter>
                
            //     <div>
            //         {/* <nav class="navbar navbar-expand-lg navbar-dark bg-dark"> */}
            //         <nav class="navbar navbar-expand-lg ">
            //             <span class="navbar-brand mb-0 h1"><img src="images/cedge_logo2_150_50.png" alt=""/></span>
            //             {/* <button class="navbar-toggler" type="button" 
            //                     data-toggle="collapse" data-target="#navbarText" 
            //                     aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            //                 <span class="navbar-toggler-icon"></span>
            //             </button> */}
            //             {/* <div class="collapse navbar-collapse" id="navbarText">
            //                 <ul class="navbar-nav mr-auto" id="header">
            //                     <li class="nav-item">
            //                         <Link class="nav-link" 
            //                             to="/">Home</Link>
            //                     </li>
            //                     <li class="nav-item">
            //                         <Link  class="nav-link" 
            //                             to="/portfolioform1">Portfolio</Link>
            //                     </li>
            //                     <li class="nav-item">
            //                         <Link  class="nav-link" 
            //                             to="/hcaform1">Machine Learning</Link>
            //                     </li>
            //                     <li class="nav-item">
            //                         <Link  class="nav-link" 
            //                             to="/strategy1">Strategy 1</Link>
            //                     </li>
            //                     <li class="nav-item">
            //                         <Link  class="nav-link" 
            //                             to="/strategy2">Strategy 2</Link>
            //                     </li>
            //                     <li class="nav-item">
            //                         <Link  class="nav-link" 
            //                             to="/crypto1">Crypto 1</Link>
            //                     </li>
            //                     <li class="nav-item">
            //                         <Link  class="nav-link" 
            //                             to="/crypto2">Crypto All</Link>
            //                     </li>
            //                     <li class="nav-item">
            //                         <Link  class="nav-link" 
            //                             to="/rea">REA</Link>
            //                     </li>
                                
            //                 </ul>
            //             </div> */}
            //         </nav>
            //     </div>
            //     <div  class="container" id='frontdoor'>
            //         {/* <Route  exact path="/" component={Home} /> */}
            //         <Route  exact path="/" component={Dashboard} />
            //         <Route  path="/portfolioform1" component={PortFolioForm1} />
            //         <Route  path="/hcaform1" component={HCAForm1} />
            //         <Route  path="/rea" component={REA} />
            //         <Route  path="/strategy1" component={Strategy1} />
            //         <Route  path="/strategy2" component={Strategy2} />
            //         <Route  path="/crypto1" component={Crypto1} />
            //         <Route  path="/crypto2" component={Crypto2} />
            //         <Route  path="/dashboard/" component={Dashboard} />
            //         <Route  path="/UsingReactPlotly/" component={UsingReactPlotly} />
            //         <Route  path="/UsingPlotly/" component={UsingPlotly} />
            //         {/* <Route  path="/contact" component={Contact} /> */}
            //     </div>
            // </HashRouter>
          
        );
    }
}
export default Main;