import React from 'react';
import { useAuth } from './context/AuthContext';
import { Redirect } from 'react-router-dom';

export default function FinLLM() {
    const { user } = useAuth();

    if (!user) {
        return <Redirect to="/login" />;
    }

    return (
        <div className="container mt-5">
            <h2>FinLLM Dashboard</h2>
            {/* Add your FinLLM content here */}
        </div>
    );
}