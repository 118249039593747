import React from 'react';
import { useAuth } from './context/AuthContext';
import { useHistory } from 'react-router-dom';

export default function Login() {
    const { signup } = useAuth();
    const history = useHistory();

    async function handleGoogleSignIn() {
        try {
            console.log("Attempting sign in ......");
            
            const result = await signup();
            console.log("sign in sumccessful:", result);
            console.log("Attempting to navigate to /finllm");
            history.push('/finllm');

        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    }

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6 text-center">
                    <h2>Welcome</h2>
                    <button 
                        className="btn btn-primary mt-3"
                        onClick={handleGoogleSignIn}
                    >
                        Sign in with Google
                    </button>
                </div>
            </div>
        </div>
    );
}