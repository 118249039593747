/*
버튼 누르면 select 된거  model result hit ratio 제공

*/


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_MARKET} from './config'
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';
import Select from 'react-select';
import { modelOptions } from './cryptoList';

// import {columns} from './tickerModelsTableColumns';
import {table_columns} from './MarketTableColumns';
import {lstmcolumns} from './tickerModelsTableLSTMColumns';
import {columnsMth} from './tickerModelsTableColumnsMth';
import {lstmcolumnsMth} from './tickerModelsTableLSTMColumnsMth';
import {columns2} from './tickerModelsTableColumns2';
import Footer from './Footer';
// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
// import Chart_Scatter from './Chart_Scatter';
// import Chart_StockLine from './Chart_StockLine';
import Chart_StockLine from './Chart_StockLine_intraday';

import BootstrapTable from 'react-bootstrap-table-next';

const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

class LLM1 extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // },

        this.state = {
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done

        };

    }
    

    render() {
 
        return ( 
            <div id="pf1"><h1>Private LLMs</h1>
                {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}

                <div class="alert alert-primary" role="alert">
                    <h5>Overview</h5>
                        
                </div>

                <div>

                    <iframe
                    // src="https://cedge-market.streamlit.app/?embed=true"
                    src="https://cedgellm.a.pinggy.link"
                    height="1400"
                    style={{ width: "100%", border: "none" }}
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                    referrerPolicy="no-referrer"
                    loading="lazy"
                    title="Private LLM"
                    ></iframe>
                </div>
                <Footer />
                {/* <footer class="mastfoot mt-auto">
                        <div class="inner">
                            <p>© 2025 Copyright Chris IJ Hwang</p>
                        </div>
                        <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                        </p>
                </footer> */}
            </div>
        );
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default  LLM1;
